/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=6e91bed8"
import script from "./_id.vue?vue&type=script&lang=ts&setup=true"
export * from "./_id.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/code/src/components/Loading.vue').default,CoverImages: require('/code/src/components/img/CoverImages.vue').default,VerticalTable: require('/code/src/components/table/VerticalTable.vue').default,ContentArea: require('/code/src/components/ContentArea.vue').default,Input: require('/code/src/components/input/Input.vue').default,ShippingStatusTag: require('/code/src/components/widgets/ShippingStatusTag.vue').default,LinkText: require('/code/src/components/text/LinkText.vue').default,AddressLabel: require('/code/src/components/widgets/AddressLabel.vue').default,CustomizableHeaderTable: require('/code/src/components/table/CustomizableHeaderTable.vue').default,SplitView: require('/code/src/components/SplitView.vue').default,SectionSubTitle: require('/code/src/components/text/SectionSubTitle.vue').default,SvgTextEditor: require('/code/src/components/svg/SvgTextEditor.vue').default,GiftPagePreview: require('/code/src/components/widgets/GiftPagePreview.vue').default,FrameText: require('/code/src/components/text/FrameText.vue').default,AttachmentArea: require('/code/src/components/attachment/AttachmentArea.vue').default})
