import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _setup$touch, _setup$touch$shopify_, _setup$company, _setup$company$image, _setup$company2, _setup$touch$shopify_2, _setup$touch$shopify_3, _setup$touch$shopify_4, _setup$touch$lp, _setup$touch$lp2, _setup$touch$lp3, _setup$touch$lp4, _setup$touch$lp5;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c('Loading', {
    attrs: {
      "value": _setup.processing,
      "modal": ""
    }
  }), _vm._v(" "), _c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c(VToolbarTitle, [_c('h5', [_vm._v(_vm._s(_setup.$t('touchDetail')))])]), _vm._v(" "), _c(VSpacer), _vm._v(" "), ((_setup$touch = _setup.touch) === null || _setup$touch === void 0 ? void 0 : (_setup$touch$shopify_ = _setup$touch.shopify_product) === null || _setup$touch$shopify_ === void 0 ? void 0 : _setup$touch$shopify_.product_status) === 'ACTIVE' ? _c('button', {
    staticClass: "tw-btn tw-btn-outline-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _setup.onClickDuplicate
    }
  }, [_vm._v("\n      " + _vm._s(_setup.$t('action.duplicate')) + "\n    ")]) : _vm._e()], 1), _vm._v(" "), _c('SplitView', {
    attrs: {
      "size": "33",
      "vertical": _vm.$vuetify.breakpoint.mdAndDown
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        var _setup$touch2, _setup$touch2$shopify, _setup$touch3, _setup$touch4, _setup$touch4$shopify;
        return [_c('ContentArea', {
          attrs: {
            "border": ""
          }
        }, [_c('div', {
          staticClass: "tw-flex tw-mb-4 tw-items-center"
        }, [_c('h6', [_vm._v(_vm._s(_setup.$t('touchInfo')))]), _vm._v(" "), _c(VSpacer), _vm._v(" "), ((_setup$touch2 = _setup.touch) === null || _setup$touch2 === void 0 ? void 0 : (_setup$touch2$shopify = _setup$touch2.shopify_product) === null || _setup$touch2$shopify === void 0 ? void 0 : _setup$touch2$shopify.product_status) === 'ACTIVE' ? _c('nuxt-link', {
          staticClass: "tw-btn tw-btn-small tw-btn-outline-primary",
          attrs: {
            "to": "/touch/".concat((_setup$touch3 = _setup.touch) === null || _setup$touch3 === void 0 ? void 0 : _setup$touch3.id, "/edit")
          }
        }, [_vm._v("\n            " + _vm._s(_setup.$t('action.edit')) + "\n          ")]) : _vm._e()], 1), _vm._v(" "), _c('CoverImages', {
          staticClass: "tw-rounded-lg tw-m-auto tw-border tw-border-border",
          attrs: {
            "src": (_setup$touch4 = _setup.touch) === null || _setup$touch4 === void 0 ? void 0 : (_setup$touch4$shopify = _setup$touch4.shopify_product) === null || _setup$touch4$shopify === void 0 ? void 0 : _setup$touch4$shopify.images,
            "image-class": "!tw-max-h-[500px]",
            "dummy": !_setup.touch
          }
        }), _vm._v(" "), _c('VerticalTable', {
          staticClass: "tw-mt-4",
          attrs: {
            "headers": [{
              text: _setup.$t('touchName'),
              value: 'name'
            }, {
              text: _setup.$t('productName'),
              value: 'shopify_product.product_title'
            }, {
              text: _setup.$t('variants'),
              value: 'shopify_product.variant_title'
            }, {
              text: _setup.$t('cost'),
              value: 'cost'
            }],
            "item": _setup.touch,
            "dummy": !_setup.touch
          },
          scopedSlots: _vm._u([{
            key: "item.cost",
            fn: function fn() {
              var _setup$touch5, _setup$touch5$shopify, _setup$touch6;
              return [_setup.touch && ((_setup$touch5 = _setup.touch) === null || _setup$touch5 === void 0 ? void 0 : (_setup$touch5$shopify = _setup$touch5.shopify_product) === null || _setup$touch5$shopify === void 0 ? void 0 : _setup$touch5$shopify.product_status) !== 'ACTIVE' ? _c('span', {
                staticClass: "tw-text-subtitle-2 tw-text-error"
              }, [_vm._v("\n              " + _vm._s(_setup.$t('error.notFound.product')) + "\n            ")]) : _c('span', {
                domProps: {
                  "textContent": _vm._s(_setup.$price((_setup$touch6 = _setup.touch) === null || _setup$touch6 === void 0 ? void 0 : _setup$touch6.shopify_product.variant_price))
                }
              })];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }, {
      key: "second",
      fn: function fn() {
        return [_c('ContentArea', {
          attrs: {
            "border": ""
          }
        }, [_c('h6', {
          staticClass: "mb-6"
        }, [_vm._v(_vm._s(_setup.$t('shippingStats')))]), _vm._v(" "), _c('Input', {
          staticClass: "mb-6",
          attrs: {
            "label": _setup.$t('keyword'),
            "clearable": "",
            "prepend-inner-icon": "mdi-magnify",
            "placeholder": _setup.$t('placeholder.keyword.touches')
          },
          model: {
            value: _setup.filterOptions.keywordText,
            callback: function callback($$v) {
              _vm.$set(_setup.filterOptions, "keywordText", $$v);
            },
            expression: "filterOptions.keywordText"
          }
        }), _vm._v(" "), _c(_setup.OrderStats, {
          staticClass: "my-6",
          attrs: {
            "stats": _setup.orderStats,
            "dummy": _setup.loadingStats
          }
        }), _vm._v(" "), _c('CustomizableHeaderTable', {
          attrs: {
            "sort-desc": ['created_at'],
            "headers": [{
              text: 'ステータス',
              value: 'get_status_display'
            }, {
              text: 'キャンペーン名',
              value: 'campaign.name',
              multiLine: true
            }, {
              text: '氏名',
              value: 'contact_name',
              hideable: false
            }, {
              text: '敬称',
              value: 'title',
              empty: _setup.$t('notSet')
            }, {
              text: '会社名',
              value: 'contact.account.name',
              multiLine: true,
              empty: _setup.$t('notSet')
            }, {
              text: 'Webサイト',
              value: 'contact.account.site_url',
              multiLine: true,
              empty: _setup.$t('notSet')
            }, {
              text: '部署',
              value: 'contact.department',
              empty: _setup.$t('notSet')
            }, {
              text: '役職',
              value: 'contact.job_title',
              empty: _setup.$t('notSet')
            }, {
              text: '配送先住所',
              value: 'shipping_address_snapshot',
              ellipsis: false,
              empty: _setup.$t('notSet')
            }, {
              text: 'オーダー作成者',
              value: 'created_by.name'
            }, {
              text: 'オーダー発注日',
              value: 'order_date',
              transform: _vm.$date,
              empty: _setup.$t('notOrder')
            }, {
              text: 'オーダー作成日',
              value: 'created_at',
              transform: _vm.$date
            },
            // TODO APIの対応が必要 { text: 'QR遷移先URL', value: '', empty: $t('none') },
            // TODO APIの対応が必要 { text: 'WOWリンク', value: '', empty: $t('none') },
            {
              text: '到着希望日/発行日',
              value: 'shipping_date'
            }, {
              text: 'QR反響/クリック',
              value: 'touched_at',
              empty: _setup.$t('none')
            }, {
              text: 'コスト',
              value: 'price',
              align: 'center',
              transform: _setup.$price
            }, {
              text: 'コメント数',
              value: 'comment_count',
              align: 'center'
            }, {
              text: '承認者',
              value: 'authorizer',
              getter: function getter(val) {
                var _val$authorizer$name, _val$authorizer, _val$assigned_to;
                return (_val$authorizer$name = (_val$authorizer = val.authorizer) === null || _val$authorizer === void 0 ? void 0 : _val$authorizer.name) !== null && _val$authorizer$name !== void 0 ? _val$authorizer$name : (_val$assigned_to = val.assigned_to) === null || _val$assigned_to === void 0 ? void 0 : _val$assigned_to.name;
              },
              empty: _setup.$t('notSet')
            }, {
              text: '所有者(担当者)',
              value: 'contact.owner.name',
              empty: _setup.$t('notSet')
            }],
            "default-headers": ['氏名', '会社名', '到着希望日/発行日', 'ステータス', 'QR反響/クリック', 'コスト', 'コメント数'],
            "items": _setup.orders,
            "item-key": "id",
            "loading": _setup.loading,
            "options": _setup.tableOptions,
            "server-items-length": _setup.ordersTotalCount,
            "disable-sort": true
          },
          on: {
            "update:options": function updateOptions($event) {
              _setup.tableOptions = $event;
            },
            "click:row": function clickRow(item, _, event) {
              return _setup.onClickItem(item, event);
            },
            "auxclick:row": function auxclickRow(event, row) {
              return _setup.onClickItem(row.item, event);
            }
          },
          scopedSlots: _vm._u([{
            key: "item.get_status_display",
            fn: function fn(_ref) {
              var item = _ref.item;
              return [_c('ShippingStatusTag', {
                attrs: {
                  "text": item.get_status_display,
                  "status": item.status
                }
              })];
            }
          }, {
            key: "item.campaign.name",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [item.campaign ? _c('LinkText', {
                attrs: {
                  "to": "/campaign/".concat(item.campaign.id)
                }
              }, [_vm._v("\n              " + _vm._s(item.campaign.name) + "\n            ")]) : _vm._e()];
            }
          }, {
            key: "item.contact_name",
            fn: function fn(_ref3) {
              var _item$contact;
              var item = _ref3.item;
              return [item.contact ? _c('LinkText', {
                attrs: {
                  "to": (_item$contact = item.contact) !== null && _item$contact !== void 0 && _item$contact.id ? "/contact/".concat(item.contact.id) : null
                }
              }, [_vm._v("\n              " + _vm._s(_setup.$fullName(item.contact)) + "\n            ")]) : _vm._e()];
            }
          }, {
            key: "item.contact.account.name",
            fn: function fn(_ref4) {
              var _item$contact2, _item$contact3;
              var item = _ref4.item;
              return [(_item$contact2 = item.contact) !== null && _item$contact2 !== void 0 && _item$contact2.account ? _c('LinkText', {
                attrs: {
                  "text": item.contact.account.name,
                  "to": item.contact.account.id ? "/accounts/".concat(item.contact.account.id) : null
                }
              }) : (_item$contact3 = item.contact) !== null && _item$contact3 !== void 0 && _item$contact3.crm_company_name ? [_vm._v("\n              " + _vm._s(item.contact.crm_company_name) + "\n            ")] : _vm._e()];
            }
          }, {
            key: "item.contact.account.site_url",
            fn: function fn(_ref5) {
              var _item$contact4, _item$contact4$accoun;
              var item = _ref5.item;
              return [(_item$contact4 = item.contact) !== null && _item$contact4 !== void 0 && (_item$contact4$accoun = _item$contact4.account) !== null && _item$contact4$accoun !== void 0 && _item$contact4$accoun.site_url ? _c('LinkText', {
                attrs: {
                  "text": item.contact.account.site_url,
                  "to": item.contact.account.site_url
                }
              }) : _vm._e()];
            }
          }, {
            key: "item.shipping_address_snapshot",
            fn: function fn(_ref6) {
              var item = _ref6.item;
              return [item.is_egift ? _c('span', {
                staticClass: "text-caption disabled-text--text"
              }, [_vm._v("\n              " + _vm._s(_setup.$t('noDelivery')) + "\n            ")]) : item.shipping_address_snapshot ? _c('AddressLabel', {
                attrs: {
                  "small": "",
                  "detail": "",
                  "rows": "",
                  "max-width": "200",
                  "address": item.shipping_address_snapshot
                }
              }) : _vm._e()];
            }
          }, {
            key: "item.shipping_date",
            fn: function fn(_ref7) {
              var item = _ref7.item;
              return [!item.is_egift || item.status === 9 ? [_vm._v("\n              " + _vm._s(_vm.$date(item.shipping_date)) + "\n            ")] : _c('div', {
                staticClass: "text-caption disabled-text--text"
              }, [_vm._v("\n              " + _vm._s(_setup.$t('notIssue')) + "\n            ")])];
            }
          }, {
            key: "item.touched_at",
            fn: function fn(_ref8) {
              var _item$order_line_item, _item$order_line_item2, _item$order_line_item3;
              var item = _ref8.item;
              return [item.touched_at ? [_vm._v("\n              " + _vm._s(_vm.$date(item.touched_at)) + "\n              "), _c('br'), _vm._v("\n              " + _vm._s(_vm.$time(item.touched_at)) + "\n            ")] : (_item$order_line_item = item.order_line_items) !== null && _item$order_line_item !== void 0 && (_item$order_line_item2 = _item$order_line_item[0]) !== null && _item$order_line_item2 !== void 0 && (_item$order_line_item3 = _item$order_line_item2.line_item_lp) !== null && _item$order_line_item3 !== void 0 && _item$order_line_item3.opened_at ? [_vm._v("\n              " + _vm._s(_vm.$date(item.order_line_items[0].line_item_lp.opened_at)) + "\n              "), _c('br'), _vm._v("\n              " + _vm._s(_vm.$time(item.order_line_items[0].line_item_lp.opened_at)) + "\n            ")] : _vm._e()];
            }
          }, {
            key: "item.comment_count",
            fn: function fn(_ref9) {
              var item = _ref9.item;
              return [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-comment-outline")]), _vm._v("\n            " + _vm._s(item.comment_count) + "\n          ")];
            }
          }])
        })], 1)];
      },
      proxy: true
    }])
  }), _vm._v(" "), _setup.touch && (_setup.productTexts.length > 0 || _setup.hasGiftVariant) ? _c('ContentArea', {
    attrs: {
      "border": ""
    }
  }, [_c('SectionSubTitle', {
    staticClass: "tw-mb-4"
  }, [_vm._v("\n      " + _vm._s([_setup.productTexts.length > 0 ? _setup.$t('printedText') : null, _setup.hasGiftVariant ? _setup.$t('giftPage') : null].filter(function (item) {
    return item != null;
  }).join('・')) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "tw-flex tw-flex-row tw-flex-wrap tw-gap-4 tw-mb-4"
  }, [_setup.productTexts.length > 0 && _setup.productCustoms.template ? _c('SvgTextEditor', {
    staticClass: "[&_.svg-wrapper]:tw-flex [&_.svg-wrapper]:tw-flex-row [&_.svg-wrapper]:tw-flex-wrap [&_.svg-wrapper]:tw-gap-4 [&_.svg-wrapper]:tw-items-center [&_.svg-wrapper_svg]:tw-size-fit [&_.svg-wrapper_svg]:tw-max-w-[200px] [&_.svg-wrapper_svg]:tw-max-h-[200px] [&_.svg-wrapper_svg]:tw-border",
    attrs: {
      "value": _setup.customTexts,
      "svg": _setup.productCustoms.template,
      "qr-url": "https://smapo.co",
      "logo": (_setup$company = _setup.company) === null || _setup$company === void 0 ? void 0 : _setup$company.image,
      "raw": ""
    }
  }) : _vm._e(), _vm._v(" "), _setup.hasGiftVariant ? _c('GiftPagePreview', {
    staticClass: "[&>.content]:!tw-overflow-y-hidden tw-pointer-events-none !tw-m-0",
    attrs: {
      "width": 120,
      "logo": (_setup$company$image = (_setup$company2 = _setup.company) === null || _setup$company2 === void 0 ? void 0 : _setup$company2.image) !== null && _setup$company$image !== void 0 ? _setup$company$image : undefined,
      "product-title": (_setup$touch$shopify_2 = _setup.touch.shopify_product.display_gift_name) !== null && _setup$touch$shopify_2 !== void 0 ? _setup$touch$shopify_2 : _setup.touch.shopify_product.product_title,
      "product-image": (_setup$touch$shopify_3 = _setup.touch.shopify_product.display_gift_image) !== null && _setup$touch$shopify_3 !== void 0 ? _setup$touch$shopify_3 : (_setup$touch$shopify_4 = _setup.touch.shopify_product.images) === null || _setup$touch$shopify_4 === void 0 ? void 0 : _setup$touch$shopify_4[0],
      "user-name": (_setup$touch$lp = _setup.touch.lp) === null || _setup$touch$lp === void 0 ? void 0 : _setup$touch$lp.sender,
      "message": (_setup$touch$lp2 = _setup.touch.lp) === null || _setup$touch$lp2 === void 0 ? void 0 : _setup$touch$lp2.message,
      "cta-text": (_setup$touch$lp3 = _setup.touch.lp) === null || _setup$touch$lp3 === void 0 ? void 0 : _setup$touch$lp3.cta_text,
      "cta-link": (_setup$touch$lp4 = _setup.touch.lp) === null || _setup$touch$lp4 === void 0 ? void 0 : _setup$touch$lp4.cta_link
    }
  }) : _vm._e()], 1), _vm._v(" "), _setup.productTexts.length > 0 ? _c('VerticalTable', {
    attrs: {
      "headers": [].concat(_toConsumableArray(_setup.productTexts.map(function (text) {
        return {
          text: text.label,
          value: text.custom_id,
          getter: function getter(val) {
            return text.text;
          }
        };
      })), [{
        text: _setup.$t('qrUrl'),
        value: 'url'
      }]),
      "item": _setup.touch
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref10) {
        var value = _ref10.value,
          text = _ref10.text;
        return [value !== 'url' ? _c('FrameText', {
          attrs: {
            "value": text,
            "pre": "",
            "x-small": ""
          }
        }) : _c('span', {
          domProps: {
            "textContent": _vm._s(text)
          }
        })];
      }
    }], null, false, 2615431406)
  }) : _vm._e(), _vm._v(" "), _setup.hasGiftVariant ? _c('VerticalTable', {
    staticClass: "tw-mt-4",
    attrs: {
      "headers": [{
        text: _setup.$t('giftSender'),
        value: 'sender'
      }, {
        text: _setup.$t('giftMessage'),
        value: 'message'
      }, {
        text: _setup.$t('buttonName'),
        value: 'cta_text',
        half: true
      }, {
        text: _setup.$t('destinationUrl'),
        value: 'cta_link',
        half: true
      }],
      "item": (_setup$touch$lp5 = _setup.touch.lp) !== null && _setup$touch$lp5 !== void 0 ? _setup$touch$lp5 : {}
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref11) {
        var value = _ref11.value,
          text = _ref11.text;
        return [value !== 'cta_link' ? _c('FrameText', {
          attrs: {
            "value": text,
            "pre": "",
            "x-small": ""
          }
        }) : _c('span', {
          domProps: {
            "textContent": _vm._s(text)
          }
        })];
      }
    }], null, false, 684579596)
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _setup.touch && _setup.touch.attachments.length > 0 ? _c('ContentArea', [_c('SectionSubTitle', {
    staticClass: "tw-mb-4",
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('button', {
          staticClass: "tw-btn tw-btn-text-primary",
          attrs: {
            "type": "button"
          },
          on: {
            "click": _setup.$openAboutPrinting
          }
        }, [_c('mdi-help-circle', {
          staticClass: "tw-size-4"
        }), _vm._v("\n          " + _vm._s(_setup.$t('aboutPrinting')) + "\n        ")], 1)];
      },
      proxy: true
    }], null, false, 1911389299)
  }, [_vm._v("\n      " + _vm._s(_setup.$t('printedMatter')) + "\n      ")]), _vm._v(" "), _c('AttachmentArea', {
    attrs: {
      "attachments": _setup.touch.attachments
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "tw-flex tw-justify-center tw-mt-6"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-outline-error",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _setup.onClickDelete
    }
  }, [_vm._v("\n      " + _vm._s(_setup.$t('action.deletionTouch')) + "\n    ")])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };